import { helpPages } from '@/constants';

export const fatherRouteName = 'protocolos-eletronicos';

export default [
  {
    path: 'protocolos-eletronicos/:id?',
    name: 'protocolos-eletronicos',
    component: () => import(/* webpackChunkName: "protocolos-eletronicos" */ './index.vue'),
    meta: {
      title: 'Processos',
      subtitle: 'Protocolos Eletrônicos',
      helpPage: helpPages['protocolos-eletronicos'],
      applicationKey: 'ressus',
      action: 'view',
      fatherRoute: fatherRouteName,
    },
    props: true,
  },
  {
    name: 'documentos-comprobatorios-protocolos',
    path: '/protocolos-eletronicos/:atendimentoId/documentos-comprobatorios/:formularioId',
    component: () =>
      import(/* webpackChunkName: "documentos-comprobatorios" */ './documentos-comprobatorios.vue'),
    meta: {
      subtitle: 'Documentos comprobatórios pendentes',
      helpPage: helpPages['protocolos-eletronicos'],
      applicationKey: 'ressus',
      action: 'view',
      fatherRoute: fatherRouteName,
    },
    props: true,
  },
];
